import React from "react";
import "./rotating_heading_styles.scss";

const RotatingText = () => {
  return (
    <>
      <div className="text-xl min-[380px]:text-2.25xl sm:text-3.75xl !leading-[normal] font-semibold text-center lg:mb-16 mb-10 ">
        <div className="flex h-8 min-[380px]:h-10 sm:h-12 justify-center ml-4 min-[380px]:ml-7">
          <p className="mr-[0.25em]">Your</p>
          <div className="rotating_words">
            <span className="word">Image</span>
            <span className="word">Video</span>
            <span className="word">DICOM</span>
            <span className="word">SAR</span>
            <span className="word">ECG</span>
          </div>
        </div>
        <p>&nbsp; data labeled faster</p>
      </div>
    </>
  );
};

export default RotatingText;

import React, { useState } from "react";

const Item = ({ icon, hoverableIcon, listItem, link }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <a
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      href={link?.url}
      target="_blank"
      className="group"
    >
      <div className="flex py-4 gap-x-3.5 items-start rounded-lg  group-hover:bg-gray-1500 group-hover:cursor-pointer outline-none transition ease-in-out duration-150 px-1">
        <img
          src={isHovered ? hoverableIcon?.url : icon?.url}
          width={icon?.dimensions?.width || 0}
          height={icon?.dimensions?.height || 0}
          loading="lazy"
          className="flex items-center justify-center flex-shrink-0 text-white h-7 w-7"
          alt={icon?.alt || "card_icon"}
        />

        <p className="text-xl font-medium text-gray-2500">{listItem?.text}</p>
      </div>
    </a>
  );
};

const IndustriesSection = ({ heading, label, items, imgSectionItems }) => {
  return (
    <section className="flex flex-col-reverse xl:flex-row sm:flex-col gap-y-18">
      <div className="flex flex-col px-6 lg:min-w-max sm:px-0">
        <div className="text-base font-medium text-center uppercase text-purple-1500 lg:text-left">
          {label}
        </div>

        <h3 className="text-gray-2500 text-2.25xl sm:text-3.25xl lg:text-3.75xl tracking-tighter_1 sm:leading-10 font-semibold pt-5 lg:mb-12 sm:mb-18 mb-9 lg:text-left text-center">
          {heading}
        </h3>
        <div className="flex flex-row flex-wrap self-center justify-center px-0 mb-5 gap-x-3 gap-y-2 sm:hidden xs:px-4">
          {imgSectionItems?.map((item, key) => {
            const { section_image } = item || {};
            return (
              <div
                className={`${
                  key % 3 !== 0 && key % 4 !== 0 ? "w-52" : "w-24"
                } h-24 `}
              >
                <img
                  src={section_image?.url}
                  width={section_image?.dimensions?.width || 0}
                  height={section_image?.dimensions?.height || 0}
                  loading="lazy"
                  className="object-cover w-full h-full rounded-xl"
                  alt={section_image?.alt || "card_icon"}
                />
              </div>
            );
          })}
        </div>
        <div className="sm:columns-2 columns-1">
          {items?.map((item, index) => {
            const { icon, list_item, link, hoverable_icon } = item || {};
            return (
              <Item
                key={index}
                icon={icon}
                hoverableIcon={hoverable_icon}
                link={link}
                listItem={list_item}
              />
            );
          })}
        </div>
      </div>

      <div className="flex-row flex-wrap self-center justify-center hidden gap-x-3 gap-y-3 sm:flex xl:px-0 lg:px-48">
        {imgSectionItems?.map((item, key) => {
          const { section_image } = item || {};
          return (
            <div
              className={`${
                key % 3 !== 0 && key % 4 !== 0
                  ? "xl:w-64 sm:w-96 w-56"
                  : "xl:w-36 sm:w-48 w-28"
              } xl:h-36 sm:h-48 h-28`}
            >
              <img
                src={section_image?.url}
                width={section_image?.dimensions?.width || 0}
                height={section_image?.dimensions?.height || 0}
                loading="lazy"
                className="object-cover w-full h-full rounded-xl"
                alt={section_image?.alt || "card_icon"}
              />
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default IndustriesSection;
